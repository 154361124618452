import Vue from 'vue'

// Initial state
const initialState = () => ({
	cedula: {
		nome: "",
		cnpjCpf: "",
		matricula: "",
		empresa: "",
		localTrabalho: "",
		celular: "",
		email: "",
		sindicato: "",
		associado: "",
    idPauta: "",
		voto: "",
		votoB: "",
    observacao: ""
	}
})

// State object
const state = initialState()

// Getters
const getters = {
	getCedula(state) {
		return state.cedula
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	sendCedula({ commit }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
			const url = `/v1/cedulas`;
			const response = await api.send(url, 'POST', state.cedula, undefined, false);
			if (response.status == 201) {
				resolve({
					status: 200,
					msg: `Seu voto foi computado com sucesso. Data/Hora do voto: ${new Date(response.data.dados.dhCriacao).toLocaleString()}`
					// msg: `Seu voto foi computado com sucesso. O protocólo gerado foi: ${response.data.dados.id} no dia: ${new Date(response.data.dados.dhCriacao).toLocaleDateString()}`
				})
			} else {
				resolve({
					status: response.status,
					msg: response.data.mensagem
				})
			}
		})
	}

}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setCedula(state, data) {
		state.cedula = data
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}