// Dependencias
import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import inicioPautaUnica from '../views/pautaUnica/Inicio.vue'
import InicioRepresentantes from '../views/representantes/Inicio.vue'

// vue router
Vue.use(VueRouter)

// Deinição das rotas
const routes = [
  {
    path: '/escolherAssembleia',
    name: 'escolherAssembleia',
    component: () => import('../views/EscolherAssembleia'),
    meta: {
      infoGathered: false,
      desc: 'Escolha de Assembleia'
    }
  },
  {
    path: '/',
    name: 'intro',
    component: Inicio,
    meta: {
      infoGathered: false,
      desc: 'Pautas Sinergia'
    }
  },
  {
    path: '/reconhecimento',
    name: 'reconhecimento',
    component: () => import('../views/Reconhecimento.vue'),
    meta: {
      infoGathered: false,
      desc: 'Reconhecimento'
    }
  },
  {
    path: '/ordemDoDia',
    name: 'ordemDoDia',
    component: () => import('../views/OrdemDoDia.vue'),
    meta: {
      infoGathered: true,
      desc: 'Ordem do dia'
    }
  },
  {
    path: '/votacao',
    name: 'votacao',
    component: () => import('../views/Votacao.vue'),
    meta: {
      infoGathered: true,
      desc: 'Votação'
    }
  },
  {
    path: '/comprovante',
    name: 'comprovante',
    component: () => import('../views/Comprovante.vue'),
    meta: {
      infoGathered: true,
      desc: 'Comprovante'
    }
  },
  /* Telas para parte replica de votação */
  {
    path: '/pautaUnica',
    name: 'inicioPautaUnica',
    component: inicioPautaUnica,
    meta: {
      infoGathered: false,
      desc: 'Pauta Única Sinergia'
    }
  },
  {
    path: '/reconhecimentoPautaUnica',
    name: 'reconhecimentoPautaUnica',
    component: () => import('../views/pautaUnica/Reconhecimento.vue'),
    meta: {
      infoGathered: false,
      desc: 'Reconhecimento'
    }
  },
  {
    path: '/ordemDoDiaPautaUnica',
    name: 'ordemDoDiaPautaUnica',
    component: () => import('../views/pautaUnica/OrdemDoDia.vue'),
    meta: {
      infoGathered: true,
      desc: 'Ordem do dia'
    }
  },
  {
    path: '/votacaoPautaUnica',
    name: 'votacaoPautaUnica',
    component: () => import('../views/pautaUnica/Votacao.vue'),
    meta: {
      infoGathered: false,
      desc: 'Votação Representantes'
    }
  },
  /* Telas para parte de representantes */
  {
    path: '/representantes',
    name: 'inicioRepresentantes',
    component: InicioRepresentantes,
    meta: {
      infoGathered: false,
      desc: 'Votação Representante'
    }
  },
  {
    path: '/reconhecimentoRepresentantes',
    name: 'reconhecimentoRepresentantes',
    component: () => import('../views/representantes/Reconhecimento.vue'),
    meta: {
      infoGathered: false,
      desc: 'Reconhecimento'
    }
  },
  {
    path: '/votacaoRepresentantes',
    name: 'votacaoRepresentantes',
    component: () => import('../views/representantes/Votacao.vue'),
    meta: {
      infoGathered: false,
      desc: 'Votação Representantes'
    }
  },
  // Fallback
  {
    path: '/*',
    name: 'pageNotFound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      infoGathered: false
    }
  },
]

// Criação do router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Exportando router
export default router
