import Vue from 'vue'

// Initial state
const initialState = () => ({
	assembleias: [],
	assembleiasDisponiveis: [],
	assembleia: {}
})

// State object
const state = initialState()

// Getters
const getters = {
	obterAssembleias(state) {
		return state.assembleias
	},
	obterAssembleiasDisponiveis(state) {
		return state.assembleiasDisponiveis
	},
	obterAssembleiaAtual() {
		return state.assembleia ? state.assembleia : null
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	listarAssembleiasDisponiveis({ commit }, {}) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
			const url = `/v1/assembleiasPorData`;
			const resposta = await api.send(url, 'POST', {}, undefined, false);
			if ([200, 201].includes(resposta.status)) {
				resposta.data.dados.disponiveis.length == 1
					? commit('setAssembleiaAtual', resposta.data.dados.disponiveis[0])
					: null

				await resposta.data.dados.disponiveis.forEach(e => {
					e.value = e.id
					e.desc = e.nome
				})
				
				commit('setAssembleiasDisponiveis', resposta.data.dados.disponiveis)
				resolve({ status: 200, msg: 'Assembleias obtidas com sucesso!' })
			} else {
				commit('setAssembleiasDisponiveis', [])
				resolve({
					status: resposta.status,
					msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
				})
			}
		})
	},
	selecionarAssembleia({ commit }, { id }) {
		const assembleiaAtual = state.assembleiasDisponiveis.find(e => e.id == id)

		const sindicatos = assembleiaAtual.sindicatos
		let empresas
		assembleiaAtual.sindicatos.forEach(e => {
			e.desc = e.nome
			e.value = e.id
			empresas = e.empresas.filter(el => el.ativa)
			empresas.forEach(el => {
				el.desc = el.nome
				el.value = el.id
			})
		})

		commit('setAssembleiaAtual', assembleiaAtual)
		commit('sindicatos/setSindicatos', sindicatos, { root: true })
		commit('sindicatos/setEmpresas', empresas, { root: true })
	},
	carregarAssembleias({ commit }, { empresa, sindicato, unico }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
			const url = `/v1/assembleiasPorData`;
			const resposta = await api.send(url, 'POST', {
				empresa: empresa,
				sindicato: sindicato,
				isPautaUnica: unico ? true : undefined
			}, undefined, false);
			if ([200, 201].includes(resposta.status)) {
				commit('setAssembleias', resposta.data.dados.disponiveis)
				resolve({ status: 200, msg: 'Assembleias obtidas com sucesso!' })
			} else {
				commit('setAssembleias', [])
				resolve({
					status: resposta.status,
					msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
				})
			}
		})
	},
	carregarRepresentantesAssembleias({ commit }, { empresa, sindicato, localTrabalho }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
			const url = `/v1/assembleiasPorData`;
			const resposta = await api.send(url, 'POST', {
				empresa: empresa,
				sindicato: sindicato,
				localTrabalho: localTrabalho,
				isRepresentante: true
			}, undefined, false);
			if ([200, 201].includes(resposta.status)) {
				commit('setAssembleias', resposta.data.dados.disponiveis)
				resolve({ status: 200, msg: 'Assembleias obtidas com sucesso!' })
			} else {
				commit('setAssembleias', [])
				resolve({
					status: resposta.status,
					msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
				})
			}
		})
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setAssembleias(state, data) {
		state.assembleias = data
	},
	setAssembleiasDisponiveis(state, data) {
		state.assembleiasDisponiveis = data
	},
	setAssembleiaAtual(state, data) {
		state.assembleia = data
	},
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}