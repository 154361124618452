<template>
  <!-- App main wrapper -->
  <div class="sinergia">
    <!-- App main content -->
    <main id="main">
      <!-- Vou router -->
      <transition name="slide-left" mode="out-in">
        <router-view :window="window"/>
      </transition>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  async beforeMount() {
    this.token = process.env.AUTH_TOKEN;
    this.wsUrl = this.isProd ? process.env.WS_PROD : process.env.WS_DEV;
    await this.instanciarComunicacao({
      token: this.token,
      baseUrl: this.wsUrl
    })
    await this.listarAssembleiasDisponiveis({})
    this.assembleiasDisponiveis.length > 1 && Object.keys(this.assembleia).length === 0
      ? this.navigateTo('escolherAssembleia')
      : null
  },
  computed: {
    ...mapGetters({
      assembleia: "assembleias/obterAssembleiaAtual",
      assembleiasDisponiveis: "assembleias/obterAssembleiasDisponiveis"
    }),
    isProd() {
      return window.location.href.startsWith(process.env.PROD_URL);
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data: () => ({
    window: {
      width: 0,
      height: 0
    }
  }),
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions({
      instanciarComunicacao: 'auth/instanciarComunicacao',
      listarAssembleiasDisponiveis: "assembleias/listarAssembleiasDisponiveis"
    }),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    navigateTo(name) {
      this.$router.push({ name });
    },
  }
}
</script>

<style lang="scss">
@import "./assets/styles/main";
</style>
