import axios from 'axios';

let instancia = null;

const privateProps = new WeakMap();

class HttpClient {
	constructor(baseURL, token, timeout = 15000) {
		if (instancia) return instancia;
		instancia = this;
		let options = {
			baseURL: baseURL,
			timeout: timeout,
		};
		let ax = axios.create(options);
		if (token) ax.defaults.headers.common['Authorization'] = token;
		privateProps.set(this, {
			axios: ax,
			baseURL: baseURL,
			timeout: timeout,
			token: token,
			cache: {}
		})
	}

	get axios() {
		return privateProps.get(this).axios;
	}

	get baseURL() {
		return privateProps.get(this).baseURL;
	}

	get timeout() {
		return privateProps.get(this).timeout;
	}

	get token() {
		return privateProps.get(this).token;
	}

	set token(val) {
		if (val) {
			privateProps.get(this).token = val;
			privateProps.get(this).axios.defaults.headers.common['Authorization'] = val;
		} else {
			privateProps.get(this).token = null;
			privateProps.get(this).axios.defaults.headers.common = {};
		}
	}

	async getCache(options) {
		let cache = privateProps.get(this).cache;
		if (typeof cache[options.url] == 'undefined') {
			cache[options.url] = await this.axios(options)
		}
		return cache[options.url];
	}

	async send(endpoint, method, data, timeout, cache = true, headers) {
		let options = {
			method: method,
			url: endpoint,
		}
		if (data) {
			if (method == 'GET') {
				let query = '';
				let i = 0;
				for (let key in data) {
					if (i > 0) query += '&';
					query += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
					i++;
				}
				options.url += '?' + query;
			} else {
				cache = false;
				options.data = data;
			}
		}
		if (headers) {
			options.headers = headers;
		}

		if (timeout) options.timeout = timeout;
		try {
			let response;
			if (cache) {
				response = await this.getCache(options);
			} else {
				response = await this.axios(options);
			}
			return response;
		} catch (e) {
			if (!e.response) {
				var data = {
					status: 500,
					data: {
						retorno: e.message
					}
				}
				return data;
			} else {
				return e.response;
			}
		}
	}
}

export default HttpClient;