import Vue from 'vue'

// Initial state
const initialState = () => ({
	reconhecimento: {
		nome: "",
		cnpjCpf: "",
		matricula: "",
		empresa: "",
		localTrabalho: "",
		celular: "",
		email: "",
		sindicato: "",
		associado: "",
		// ativo: ""
	}
})

// State object
const state = initialState()

// Getters
const getters = {
	getReconhecimento(state) {
		return state.reconhecimento
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	atualizarReconhecimento({ commit }, reconhecimento) {
		commit('setReconhecimento', reconhecimento);
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setReconhecimento(state, data) {
		state.reconhecimento = data
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}