import Vue from 'vue'

// Initial state
const initialState = () => ({
  sindicatos: [],
  empresas: [],
  sindicatoAtual: null,
  empresaAtual: null,
  dadosVotacaoRepresentante: {
    sindicato: null,
    empresa: null,
    localTrabalho: null
  },
  locaisTrabalho: []
})

// State object
const state = initialState()

// Getters
const getters = {
  obterSindicatos(state) {
    return state.sindicatos.map(s => ({
      desc: s.nome,
      value: s.id
    }))
  },
  obterEmpresas(state) {
    return state.empresas.map(e => ({
      desc: e.nome,
      value: e.id
    }))
  },
  obterLocaisTrabalho(state) {
    return state.locaisTrabalho.map(e => ({
      desc: e.nome,
      value: e.id
    }));
  },
  obterIdEmpresa(state) {
    return state.empresaAtual
  },
  obterIdSindicato(state) {
    return state.sindicatoAtual
  },
  obterDadosVotacaoRepresentante(state) {
    return state. dadosVotacaoRepresentante
  }
}

// Actions
const actions = {
  clearState({ commit }) { commit('RESET') },
  listarSindicatos({ commit }, isRepresentante = false) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
			const url = `/v1/sindicatos/listarPorAssembleia`;
      const resposta = await api.send(url, 'POST', { isRepresentante }, undefined, false);
      if ([200, 201, 404].includes(resposta.status)) {
        commit('setSindicatos', resposta.data.dados)
        resolve({ status: resposta.status, msg: 'Lista de sindicatos recuperada' })
      } else {
        commit('setSindicatos', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },
  listarSindicatosPautaUnica({ commit }, unico = false) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
			const url = `/v1/sindicatos/listarPorAssembleia`;
      const resposta = await api.send(url, 'POST', {
        isPautaUnica: unico
      }, undefined, false);
      if ([200, 201, 404].includes(resposta.status)) {
        commit('setSindicatos', resposta.data.dados)
        resolve({ status: resposta.status, msg: 'Lista de sindicatos recuperada' })
      } else {
        commit('setSindicatos', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },
  listarEmpresas({ commit }, id) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
			const url = `/v1/sindicatos/obter/${id}`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('setEmpresas', resposta.data.dados.empresas)
        resolve({ status: resposta.status, msg: 'Empresas pertencentes a esse sindicato foram recuperada' })
      } else {
        commit('setEmpresas', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },
  listarLocaisTrabalho({ commit }, empresa) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/locaisTrabalhoPorAssembleia`;
      const resposta = await api.send(url, 'POST', { empresa }, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('setLocaisTrabalho', resposta.data.dados)
        resolve({ status: resposta.status, msg: 'Empresas pertencentes a esse sindicato foram recuperada' })
      } else {
        commit('setLocaisTrabalho', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },
  atualizarDadosNavegacaoRepresentante({ commit }, dados) {
    commit('setDadosVotacaoRepresentante', dados)
  }
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
  },
  setSindicatos(state, data) {
    state.sindicatos = data
  },
  setEmpresas(state, data) {
    state.empresas = data
  },
  setLocaisTrabalho(state, data) {
    state.locaisTrabalho = data
  },
  setDadosNavegacao(state, data) {
    state.sindicatoAtual = data.sindicato
    state.empresaAtual = data.empresa
  },
  setDadosVotacaoRepresentante(state, data) {
    state.dadosVotacaoRepresentante = data
  }
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}