<template>
  <div class="bg-primary">
    <div class="top-navigation">
      <div @click="navigateToSinergia" class="navigate-back" :class="{'back-opacity': window.width > 768}">
        <i class="fas fa-arrow-left mr-3"></i> 
        <span :class="{ 'd-none': window.width <= 768 }">
          VOLTAR AO SITE
        </span>
      </div>
      <div v-if="window.width <= 768" class="route-name text-uppercase">
        Voltar ao site
      </div>
      <div v-else class="route-name text-uppercase">
        {{ this.$route.meta.desc }}
      </div>
    </div>
    <div class="logo-container">
      <b-container>
        <div class="row">
          <img v-if="window.width <= 768" class="logo" src="@/assets/images/logo.png" alt="logo Sinergia">
          <img v-else class="logo" src="@/assets/images/logo-color.png" alt="logo Sinergia">
          <img class="logo-cut" src="@/assets/images/logo-cut.png" alt="logo CUT">
        </div>
      </b-container>
    </div>
    <b-container>
      <div class="row">
        <div class="col-12 text-white text-center intro-info pb-4">
          <p>
            <small class="text-uppercase">
              ELEIÇÃO DE REPRESENTANTES SINDICAIS 2020/2024
            </small>
          </p>
          <p>
            <small>
              Neste momento atípico de pandemia, na luta contra a propagação do 
              Covid-19, da retirada dos direitos trabalhistas e do déficit da 
              economia,  mais do que nunca é preciso adequar os procedimentos, 
              sem prejuízos à qualidade do debate e das determinações estatutárias, 
              para garantir a organização dos trabalhadores e trabalhadoras.
            </small>
          </p>
          <p>
            <small>
              Diante desses desafios, a Direção do Sinergia Campinas abriu o 
              processo eleitoral para eleger Representantes Sindicais em empresas 
              energéticas de diversas localidades de sua base no Estado de 
              São Paulo. A votação ocorrerá entre às 07h do dia 19 até às 18h 
              do dia 23 de junho. Se você é sindicalizado, participe desse processo 
              democrático. Escolha seu candidato e vote!
            </small>
          </p>
        </div>
      </div>
    </b-container>
    <div class="container fixed-bottom">
      <div class="row">
        <div class="col-12 mb-4">
          <base-button
            type="primary"
            class="w-100"
            @click="navigateTo('reconhecimentoRepresentantes')"
          >Votar no Representante</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'introRepresentante',
  methods: {
    navigateTo(name) {
      this.$router.push({ name })
    },
    navigateToSinergia() {
      window.location.replace("https://sinergiaspcut.com.br");
    }
  },
  props: ['window']
}
</script>