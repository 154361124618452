<template>
  <div class="bg-primary">
    <div class="top-navigation">
      <div
        v-if="assembleiasDisponiveis && assembleiasDisponiveis.length > 1"
        @click="navigateTo('escolherAssembleia')"
        class="navigate-back"
      >
        <i class="fas fa-arrow-left mr-3"></i>
        <span :class="{ 'd-none': window.width <= 768 }">
          SELECIONAR OUTRA ASSEMBLEIA
        </span>
      </div>
      <div
        v-else
        @click="navigateToSinergia"
        class="navigate-back"
        :class="{ 'back-opacity': window.width > 768 }"
      >
        <i class="fas fa-arrow-left mr-3"></i>
        <span :class="{ 'd-none': window.width <= 768 }">
          VOLTAR AO SITE
        </span>
      </div>
      <div v-if="window.width <= 768" class="route-name text-uppercase">
        Voltar ao site
      </div>
      <div v-else class="route-name text-uppercase">
        {{ this.$route.meta.desc }}
      </div>
    </div>
    <div class="logo-container">
      <b-container>
        <div class="row">
          <img
            v-if="window.width <= 768"
            class="logo"
            src="@/assets/images/logo.png"
            alt="logo Sinergia"
          />
          <img v-else class="logo" src="@/assets/images/logo-color.png" alt="logo Sinergia" />
          <img class="logo-cut" src="@/assets/images/logo-cut.png" alt="logo CUT" />
        </div>
      </b-container>
    </div>
    <b-container>
      <div class="row">
        <div class="col-12 text-white text-center intro-info pb-4">
          <div class="small" v-html="assembleia.descricao"></div>
          <p>
            <small>
              <b>Conheça a pauta sugerida. Vote! Participe!</b>
            </small>
          </p>
        </div>
      </div>
    </b-container>
    <div class="container fixed-bottom">
      <div class="row">
        <div class="col-12 mb-4">
          <base-button type="primary" class="w-100" @click="navigateTo('reconhecimento')"
            >Votar na Assembleia</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'intro',
  methods: {
    navigateTo(name) {
      this.$router.push({ name });
    },
    navigateToSinergia() {
      window.location.replace('https://sinergiaspcut.com.br');
    },
  },
  computed: {
    ...mapGetters({
      assembleiasDisponiveis: "assembleias/obterAssembleiasDisponiveis",
      assembleia: "assembleias/obterAssembleiaAtual"
    }),
  },
  props: ['window'],
};
</script>
