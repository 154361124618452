import Vue from 'vue'

// Initial state
const initialState = () => ({
  candidatos: []
})

// State object
const state = initialState()

// Getters
const getters = {
  obterCandidatos() {
    return state.candidatos
  }
}

// Actions
const actions = {
  clearState({ commit }) { commit('RESET') },
  carregarCandidatosVotacao({ commit }, { sindicato, empresa, localTrabalho }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
			const url = `/v1/candidatos/listar`;
      const resposta = await api.send(url, 'POST', {
        sindicato, empresa, localTrabalho
      }, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('atribuirCandidatos', resposta.data.dados)
        resolve({ status: 200, msg: 'Candidatos obtidos com sucesso' })
      } else {
        commit('atribuirCandidatos', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  }  
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
  },
  atribuirCandidatos(state, data) {
    state.candidatos = data
  }
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}