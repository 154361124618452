import Vue from 'vue'

// Estado inicial
const initialState = () => ({})

// Objeto de estado
const state = initialState()

// Getters
const getters = {}

// Ações
const actions = {
  clearState({ commit }) { commit('RESET') },
  instanciarComunicacao(_, { token, baseUrl }) {
    new Vue.prototype.$httpClient(baseUrl, token)
	}
}

// Mutações
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  }
}

// Exoprtanto módulo
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}