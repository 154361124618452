<template>
  <div class="bg-primary">
    <div class="top-navigation">
      <div @click="navigateToSinergia" class="navigate-back" :class="{'back-opacity': window.width > 768}">
        <i class="fas fa-arrow-left mr-3"></i> 
        <span :class="{ 'd-none': window.width <= 768 }">
          VOLTAR AO SITE
        </span>
      </div>
      <div v-if="window.width <= 768" class="route-name text-uppercase">
        Voltar ao site
      </div>
      <div v-else class="route-name text-uppercase">
        {{ this.$route.meta.desc }}
      </div>
    </div>
    <div class="logo-container">
      <b-container>
        <div class="row">
          <img v-if="window.width <= 768" class="logo" src="@/assets/images/logo.png" alt="logo Sinergia">
          <img v-else class="logo" src="@/assets/images/logo-color.png" alt="logo Sinergia">
          <img class="logo-cut" src="@/assets/images/logo-cut.png" alt="logo CUT">
        </div>
      </b-container>
    </div>
    <b-container>
      <div class="row">
        <div class="col-12 text-white text-center intro-info pb-4">
          <p><small>Em tempos de pandemia da Covid-19, isolamento social e visando a proteção da saúde e manutenção dos empregos, o Sinergia CUT repensa o funcionamento da CS 2020 com realização de assembleias virtuais. A prioridade da Pauta é a aplicação do reajuste sobre as cláusulas econômicas com a reposição da inflação nos salários e benefícios e as seguintes garantias: da Política de Remuneração por Resultados e sua correção; de data-base com prorrogação do ACT por mais um ano; de negociação coletiva; do emprego (suspensão de todas as demissões, exceto justa causa, enquanto durar a pandemia); e a manutenção das cláusulas do Acordo anterior. Participe!</small></p>
        </div>
      </div>
    </b-container>
    <div class="container fixed-bottom">
      <div class="row">
        <div class="col-12 mb-4">
          <base-button
            type="primary"
            class="w-100"
            @click="navigateTo('reconhecimentoPautaUnica')"
          >Votar na Assembleia</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'intro',
  methods: {
    navigateTo(name) {
      this.$router.push({ name })
    },
    navigateToSinergia() {
      window.location.replace("https://sinergiaspcut.com.br");
    }
  },
  props: ['window']
}
</script>