import Vue from 'vue'

// Initial state
const initialState = () => ({
	pauta: {
		dataFinal: "",
		dataInicial: "",
		descricao: "",
		id: "",
		nome: ""
	}
})

// State object
const state = initialState()

// Getters
const getters = {
	getPauta(state) {
		return state.pauta
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	updateProfile({ commit }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient('https://xyo44mtze5.execute-api.us-east-1.amazonaws.com/', '0XHDKKSHDKKSII556YUUIO')
			const url = `/v1/pautas`;
			const response = await api.send(url, 'GET', null, undefined, false);
			if (response.status == 200) {
				commit('setPauta', response.data.dados[0])
				resolve({
					status: 200,
					msg: 'Cupons recuperados para esse usuário.'
				})
			} else {
				commit('setPauta', null)
				resolve({
					status: 404,
					msg: 'Nenhum cupom encontrado para esse usuário.'
				})
			}
		})
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setPauta(state, data) {
		state.pauta = data
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}